import { environment } from '../../../environments/environment';
import { EmbryoService } from '../../Services/Embryo.service';
import { Component, OnInit, Input } from '@angular/core';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'embryo-CollectionGallery',
  templateUrl: './CollectionGallery.component.html',
  styleUrls: ['./CollectionGallery.component.scss']
})
export class CollectionGalleryComponent implements OnInit {

  technics : any ;
  currentLanguage = 'fr';

  @Input() Technics : any;

  imagesUrl = environment.imageTechnicUrl;

  constructor(
    public translate: TranslateService,
    private EmbService : EmbryoService
  ) {
    localStorage.getItem('language') ? this.currentLanguage =  localStorage.getItem('language') : this.currentLanguage = "fr"
    translate.onLangChange.subscribe(lang=>{
      this.currentLanguage = lang;
  })

   }

  ngOnInit() {

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang
  });

   // this.technics = this.Technics;
   // this.getTechnics()
  }





}
