import { CommonSignInComponent } from '../../Global/CommonSignIn/CommonSignIn.component';
import { Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ChangeDetectorRef } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { EmbryoService } from '../../Services/Embryo.service';
import { Meta, Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

import { MatDialog } from '@angular/material';

@Component({
   selector: 'embryo-Cart',
   templateUrl: './Cart.component.html',
   styleUrls: ['./Cart.component.scss']
})
export class CartComponent implements OnInit, AfterViewChecked {

   products: any;
   quantityArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
   popupResponse: any;

   imagesUrl = environment.imageArticlecUrl;
   sessionsUrl = environment.imageSessioncUrl;
   currentLanguage: any;

   PromoCode;
   ReferralCode;

   user: any;

   userProfile: any;


   loading = false;

   resultReferralCode = true;
   resultPromoCode = true;

   ReferralCodeReductions: any
   PromoCodeReductions: any;

   PromoCodeValue = 0;
   usePromoCode = false;
   useBalance = false;
   TotalPrice = 0;
   OldPrice = 0;
   NewPrice = 0;
   reductionAmount = 0;
   order: any;

   orderProducts = [];

   OrderResponse: any;




   constructor(public embryoService: EmbryoService,
      private router: Router,
      public translate: TranslateService,
      private loadingBar: LoadingBarService,
      meta: Meta, title: Title,
      public dialog: MatDialog,
      private cdRef: ChangeDetectorRef) {

      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })

      title.setTitle(environment.MetaTile);

      meta.addTags([
         { name: 'description', content: environment.MetaDescription }
      ]);

   }

   ngOnInit() {


      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
      });

      this.user = JSON.parse(localStorage.getItem('currentUser'));

      if (this.user !== null) {

         this.userProfile = this.embryoService.UserProfile
         if (!this.userProfile) {
            this.embryoService.getProfileUser(this.user.id).then(
               resp => {
                  this.userProfile = resp['data'];

               },
               error => {
                  console.log(error);

               }
            )
         }
      }


      this.order = {
         "currency": "eur",
         "articles": [],
         "sessions": [],
         "trainings": [],
         "use_fidelities": false,
         "use_balance": false,
      }


      this.PrepareOrder()

   }

   ngAfterViewChecked(): void {
      this.cdRef.detectChanges();
   }


   PrepareOrder() {

      // let products = this.embryoService.localStorageCartProducts;
      this.orderProducts = this.embryoService.localStorageCartProducts;

      let orderIdProduct: any = []
      let orderSessions: any = [];
      let orderTrainings: any = [];

      this.orderProducts.forEach(element => {

         if (element.articleId) {
            let product = {
               articleId: element.articleId,
               quantity: element.quantity,
               name: element.name,
               price: element.price_ttc || 0
            }
            orderIdProduct.push(product)

         } else if (element.sessionId) {

            let sessions = {
               sessionId: element.sessionId,
               quantity: element.quantity,
               name: element.name,
               is_width_kit : element.is_width_kit || false,
               price: element.priceTTC || 0
            }
            orderSessions.push(sessions)
         }

         else if (element.trainingId) {

            let training = {
               trainingId: element.trainingId,
               name: element.name,
               price: element.price_ttc || 0
            }
            orderTrainings.push(training)
         }

      });


      this.order['articles'] = orderIdProduct;
      this.order['sessions'] = orderSessions;
      this.order['trainings'] = orderTrainings;


      this.embryoService.getOrderPrice(this.order).then(
         resp => {

            this.OrderResponse = resp['data'];
            this.NewPrice = this.OrderResponse.total_amount;
            this.reductionAmount = this.OrderResponse.reduction_amount;
            this.OldPrice = this.getTotalPrice();
         },
         error => {
            this.loading = false;
            this.OldPrice = this.getTotalPrice();
            console.log(error);

         }
      )

   }

   public removeProduct(value: any) {

      let message = "Are you sure you want to delete this product?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => { this.popupResponse = res; },
            err => console.log(err),
            () => this.getPopupResponse(this.popupResponse, value)
         );


   }

   public getPopupResponse(response, value) {

      let product = value;

      if (product.articleId) {

         for (let i = 0; i < this.orderProducts.length; i++) {
            if (this.orderProducts[i].articleId === product.articleId) {
               this.orderProducts.splice(i, 1);
               break;
            }
         }
      } else if (product.sessionId) {

         for (let i = 0; i < this.orderProducts.length; i++) {
            if (this.orderProducts[i].sessionId === product.sessionId) {
               this.orderProducts.splice(i, 1);
               break;
            }
         }

      } else if (product.trainingId) {

         for (let i = 0; i < this.orderProducts.length; i++) {
            if (this.orderProducts[i].trainingId === product.trainingId) {
               this.orderProducts.splice(i, 1);
               break;
            }
         }

      }

      if (response) {
         this.embryoService.removeLocalCartProduct(value);
         this.PrepareOrder()
      }
   }

   public calculateProductSinglePrice(product: any, value: any) {
      let price = 0;
      product.quantity = value;

      if (product.price_ttc) {
         price = product.price_ttc * product.quantity
      } else if (product.priceTTC) {
         price = product.priceTTC * product.quantity
      } else {
         price = product.price_ttc * product.quantity
      }
      return price;
   }

   public calculateTotalPrice() {
      let subtotal = 0;
      if (this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length > 0) {
         for (let product of this.embryoService.localStorageCartProducts) {
            if (product.price_ttc) {
               subtotal += product.price_ttc * product.quantity
            } else if (product.priceTTC) {
               subtotal += product.priceTTC * product.quantity
            } else {
               subtotal += product.price_ttc * product.quantity
            }

         }
         return subtotal;
      }
      return subtotal;

   }

   public getTotalPrice() {
      let total = 0;
      if (this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length > 0) {
         for (let product of this.embryoService.localStorageCartProducts) {

            if (product.price_ttc) {
               total += product.price_ttc * product.quantity
            } else if (product.priceTTC) {
               total += product.priceTTC * product.quantity
            } else {
               total += product.price_ttc * product.quantity
            }

         }
         return total
      }

      return total

   }



   public updateLocalCartProduct() {
      this.embryoService.updateAllLocalCartProduct(this.embryoService.localStorageCartProducts);
      this.router.navigate(['/checkout']);

      let OrderParams = {
         "use_balance": this.useBalance,
         "ref_code": this.ReferralCode || '',
         "promotion": this.order.promotion || ''
      };

      localStorage.setItem('OrderParams', JSON.stringify(OrderParams))

   }

   public getQuantityValue(product) {
      if (product.quantity) {
         return product.quantity
      } else {
         return 1;
      }
   }



   CalculePrice(Price, tax, quantity) {

      let PriceTTC = 0;
      let _tax = Number(tax) || 0;
      let _price = Number(Price) || 0;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC = ((_price * _tax / 100) + _price) * quantity;
      } catch (error) {
         PriceTTC = 0
      }
      return PriceTTC;


   }



   openDialog() {

      this.dialog.open(CommonSignInComponent, {
         width: '50%',
      });
   }


   searchForReferralCode() {


      if (this.ReferralCode !== '') {


         this.embryoService.verifyReferralCode(this.ReferralCode).then(
            resp => {

               this.resultReferralCode = resp['data']['check'];


               if (this.resultReferralCode === true) {
                  this.loading = true;
                  this.embryoService.getReferralCodeReduction().then(
                     resp => {
                        let response = resp['data'];

                        if (response) {
                           this.resultReferralCode = true
                        } else {
                           this.resultReferralCode = false;
                        }
                        this.loading = false;
                        this.order['ref_code'] = this.ReferralCode;
                        this.PrepareOrder()
                     },
                     error => {
                        this.loading = false;
                     }
                  )
               } else {
                  this.order['ref_code'] = '';
                  this.PrepareOrder()
                  this.ReferralCodeReductions = []
               }

            },
            error => {
               console.log(error);
            }
         )

      } else {
         this.order['ref_code'] = '';
         this.PrepareOrder()
         this.ReferralCodeReductions = []
         this.resultReferralCode === true;
      }


   }


   searchForPromoCode() {

      this.usePromoCode = false;
      if (this.PromoCode !== '') {
         this.loading = true;
         this.embryoService.getPromoCode(this.PromoCode).then(
            resp => {
               this.loading = false;
               this.PromoCodeReductions = resp['data']

               if (this.PromoCodeReductions.length > 0) {
                  this.resultPromoCode === true
               } else {
                  this.resultPromoCode === false
               }
            },
            error => {
               console.log(error);

            }
         )

      } else {
         this.loading = false;
         this.resultPromoCode = true
         this.PromoCodeReductions = []
      }
   }


   getReductionPrice() {
      let reduction = 0;
      if (this.usePromoCode === true && this.PromoCodeReductions && this.PromoCodeReductions.length > 0) {
         reduction = this.TotalPrice * this.PromoCodeReductions[0].value / 100;

         return reduction
      }
      return reduction
   }

   usingPromoCode() {

      if (this.usePromoCode === true) {
         this.order['promotion'] = this.PromoCodeReductions[0].code
      } else {
         this.order['promotion'] = ''
      }
      this.PrepareOrder();
   }


   usingBalance() {


      if (this.useBalance === true) {
         this.order['use_balance'] = true;
      } else {
         this.order['use_balance'] = false;
      }

      this.PrepareOrder();

   }



   CalculePriceProduct(Price, tax) {

      let PriceTTC = 0;
      let _tax = Number(tax) || 0;
      let _price = Number(Price) || 0;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC = ((_price * _tax / 100) + _price);
      } catch (error) {
         PriceTTC = 0
      }
      return (Math.round(PriceTTC * 100) / 100).toFixed(2);
   }



}
