import { EmbryoService } from '../../Services/Embryo.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder,FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'embryo-ContactForm',
  templateUrl: './ContactForm.component.html',
  styleUrls: ['./ContactForm.component.scss']
})
export class ContactFormComponent implements OnInit {

   contactForm  : FormGroup;
   emailPattern : any = /\S+@\S+\.\S+/;

   error: string = '';
   success : string = '';

   user: any;

   constructor(

      private formGroup : FormBuilder,
      private embryoService: EmbryoService,

      ) {

       }

   ngOnInit() {

      this.user = JSON.parse(localStorage.getItem('currentUser'));


      this.contactForm = this.formGroup.group({
         first_name : ['', { validators: [Validators.required] }],
         last_name  : [''],
         email      : ['', { validators: [Validators.required, Validators.pattern(this.emailPattern)] }],
         subject    : ['', { validators: [Validators.required] }],
         phone      : ['', { validators: [Validators.required] }],
         message    : ['', { validators: [Validators.required] }],
         userId     :   [''],
      })


      this.user = JSON.parse(localStorage.getItem('currentUser'));
   }

   public submitForm() {
      if(this.contactForm.valid)
      {
         this.user ? this.contactForm.controls['userId'].setValue(this.user.userId) : '';

         this.embryoService.contactUs(this.contactForm.value).then(
            resp=> { 
               if (resp['status'] == 200) {
                  this.success = "votre formulaire a été envoyé avec succes";
                  
               } else {
                  this.error = "une erreur c'est produit, veuillez réessayer"
               }
            },
            error => { console.log(error)},
         )

         setTimeout(() => {
            this.success = '';
            this.error = '';
            window.location.reload();
         }, 5000);

         

      } else {
         for (let i in this.contactForm.controls) {
            this.contactForm.controls[i].markAsTouched();
         }
      }
   }

}
