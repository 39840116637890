import { AuthGuard } from './Services/guard.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes } from '@angular/router';

import { MainComponent } from './Main/Main.component';
import { HomeThreeComponent } from './Pages/Home/HomeThree/HomeThree.component';
import { CartComponent } from './Pages/Cart/Cart.component';
import { NotFoundComponent } from './Pages/NotFound/NotFound.component';
import { ConsentementComponent } from './Pages/Consentements/Consentement.component';

// Exported functions for dynamic imports
export function loadProductsModule() {
  return import('./Pages/Products/Products.module').then(m => m.ProductsModule);
}

export function loadTechnicsModule() {
  return import('./Pages/Technics/Technics.module').then(m => m.TechnicsModule);
}

export function loadSessionModule() {
  return import('./Pages/Session/Session.module').then(m => m.SessionModule);
}

export function loadCheckoutModule() {
  return import('./Pages/Checkout/Checkout.module').then(m => m.CheckoutModule);
}

export function loadAboutModule() {
  return import('./Pages/About/About.module').then(m => m.AboutModule);
}

export function loadBlogsModule() {
  return import('./Pages/Blogs/Blogs.module').then(m => m.BlogsModule);
}

export function loadUserAccountModule() {
  return import('./Pages/UserAccount/UserAccount.module').then(m => m.UserAccountModule);
}

export const AppRoutes: Routes = [
   {
      path: '',
      redirectTo: 'home',
      pathMatch: 'full',
   },
   {
      path: '',
      component: MainComponent,
      children: [
         {
            path: '',
            component: HomeThreeComponent
         },
         {
            path: 'products',
            loadChildren: loadProductsModule
         },
         {
            path: 'technics',
            loadChildren: loadTechnicsModule
         },
         {
            path: 'cart',
            component: CartComponent
         },
         {
            path: 'not-found',
            component: NotFoundComponent
         },
         {
            path: 'consentement',
            component: ConsentementComponent
         },
         {
            path: 'session',
            loadChildren: loadSessionModule
         },
         {
            path: 'checkout',
            loadChildren: loadCheckoutModule
         },
         {
            path: '',
            loadChildren: loadAboutModule
         },
         {
            path: 'blogs',
            loadChildren: loadBlogsModule
         },
         {
            path: 'account',
            loadChildren: loadUserAccountModule,
            canActivate: [AuthGuard]
         }
      ]
   },
   {
      path: '**',
      redirectTo: 'not-found'
   }
];
