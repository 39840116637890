import { environment } from '../../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Meta, Title } from "@angular/platform-browser";

declare var $: any;

@Component({
   selector: 'embryo-ProductGrid',
   templateUrl: './ProductGrid.component.html',
   styleUrls: ['./ProductGrid.component.scss']
})
export class ProductGridComponent implements OnInit {


   ImagesSessionUrl = environment.imageSessioncUrl;
   ImagesArticleUrl = environment.imageArticlecUrl;
   ImageTrainingUrl = environment.imageTrainingUrl;

   currentLanguage: any;

   @Input() products: any;

   @Input() currency: string;

   @Input() gridLength: any;

   @Input() gridThree: boolean = false;

   @Input() type: any;

   @Output() addToCart: EventEmitter<any> = new EventEmitter();

   @Output() addToWishList: EventEmitter<any> = new EventEmitter();

   loaded = false;
   lg = 25;
   xl = 25;

   trackByObjectID(index, hit) {
      return hit.objectID;
   }

   constructor(
      public translate: TranslateService,
      public meta: Meta,
      public title: Title,
   ) {

      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })


      title.setTitle(environment.MetaTile);

      meta.addTags([
         { name: 'description', content: environment.MetaDescription }
      ]);


   }

   ngOnInit() {

      if (this.gridThree) {
         this.lg = 33;
         this.xl = 33;
      } else {
         this.lg = 25;
         this.xl = 25;
      }

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
      });
   }

   public addToCartProduct(value: any) {
      console.log(value);
      
      this.addToCart.emit(value);
   }

   public onLoad() {
      this.loaded = true;
   }

   public productAddToWishlist(value: any, parentClass) {
      if (!($('.' + parentClass).hasClass('wishlist-active'))) {
         $('.' + parentClass).addClass('wishlist-active');
      }
      this.addToWishList.emit(value);
   }

   public checkCartAlready(singleProduct) {
      let products = JSON.parse(localStorage.getItem("cart_item")) || [];
      if (!products.some((item) => item.name == singleProduct.name)) {
         return true;
      }
   }


   showPrice(Price, tax) {

      let PriceTTC = 0;
      let _tax = Number(tax) || 0;
      let _price = Number(Price) || 0;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC = ((_price * _tax / 100) + _price);
      } catch (error) {
         PriceTTC = 0
      }
      return PriceTTC;


   }


   public showDate(StartDate, EndDate) {

      let date = ''
      const startDay = new Date(StartDate).getDate();
      const endDay = new Date(EndDate).getDate();

      const startMonth = new Date(StartDate).getMonth() + 1;
      const endMonth = new Date(EndDate).getMonth() + 1;

      const startYear = new Date(StartDate).getFullYear();
      const endYear = new Date(EndDate).getFullYear();

      if (startYear === endYear) {

         if (startMonth === endMonth) {

            date = startDay + ' - ' + endDay + '/' + startMonth + '/' + startYear;
         } else {

            date = startDay + '/' + startMonth + ' - ' + endDay + '/' + endMonth + '-' + startYear;
         }

      } else {

          date = startDay + '/' + startMonth + ' - ' + endDay + '/' + endMonth + '/' + endYear;
      }

      return date;

   }


}
