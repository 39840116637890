// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyC5kr77VEU-FnklR5DBn3bPwmtmpEjYde4",
    authDomain: "embryo-version-2.firebaseapp.com",
    databaseURL: "https://embryo-version-2.firebaseio.com",
    projectId: "embryo-version-2",
    storageBucket: "embryo-version-2.appspot.com",
    messagingSenderId: "73552048992"
  },
  BoxtalApi        : "https://boxtal.dev.fabulous.l-wa.com/api/",
  baseUrl          : "https://api.dev.fabulous.odesco.education/api/",
  imageTechnicUrl  : "https://api.dev.fabulous.odesco.education/api/storage/technic/download/",
  imageArticlecUrl : "https://api.dev.fabulous.odesco.education/api/storage/article/download/",
  imageSessioncUrl : "https://api.dev.fabulous.odesco.education/api/storage/session/download/",
  imageTrainingUrl : "https://api.dev.fabulous.odesco.education/api/storage/training/download/",
  PdfCourseUrl     : "https://api.dev.fabulous.odesco.education/api/storage/course/download/",
  MetaTile : 'Fabulous Paris',
  MetaDescription : 'Bienvenue chez Fabulous.Paris, un institut de beauté situé dans le 17 ème arrondissement de Paris, dans le quartier Wagram, à deux pas du métro éponyme',

}

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
