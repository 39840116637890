import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    CurrentUser;
    canAccess = false;
    constructor(
        private router: Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


        if ( localStorage.getItem('currentUser') || localStorage.getItem('currentUser') != null ) {
            return true
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/session/signin'], { queryParams: { returnUrl: state.url } });
        return false;
    }




}
