import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { MatDialogRef, MatDialog, MatDialogConfig, MatSidenav } from '@angular/material';
import { ToastaService, ToastaConfig, ToastOptions, ToastData } from 'ngx-toasta';
import 'rxjs/Rx';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '../../environments/environment';

import { ReviewPopupComponent } from '../Global/ReviewPopup/ReviewPopup.component';
import { ConfirmationPopupComponent } from '../Global/ConfirmationPopup/ConfirmationPopup.component';

interface Response {
   data: any;
}

@Injectable()
export class EmbryoService {

   sidenavOpen: boolean = false;
   paymentSidenavOpen: boolean = false;
   isDirectionRtl: boolean = false;
   featuredProductsSelectedTab: any = 0;
   newArrivalSelectedTab: any = 0;
   /**** Get currency code:- https://en.wikipedia.org/wiki/ISO_4217 *****/
   currency: string = '€';
   language: string = 'english';

   shipping: number = 12.95;
   tax: number = 27.95;

   technics: any;
   TechnicsTab: any = [];

   products: any;


   url = environment.baseUrl;
   BoxtalApi = environment.BoxtalApi;


   promoText: any;


   user: any;
   token: any;
   localStorageCartProducts: any;
   localStorageWishlist: any;
   navbarCartCount: number = 0;
   navbarWishlistProdCount = 0;
   buyUserCartProducts: any;
   UserProfile: any;

   constructor(private http: HttpClient,
      public translate: TranslateService,
      private dialog: MatDialog,
      public router: Router,
      private toastyService: ToastaService,
      private toastyConfig: ToastaConfig) {

      this.toastyConfig.position = "top-right";
      this.toastyConfig.theme = "material";
      this.calculateLocalWishlistProdCounts();
      localStorage.removeItem("user");
      localStorage.removeItem("byProductDetails");

      this.setCartItemDefaultValue();

      this.getCurrentTax();
      this.getTechnicsList();

      this.getPromoText()


      this.user = JSON.parse(localStorage.getItem('currentUser'));
      this.token = localStorage.getItem('token');
      if (this.token != '' && this.user) {

         this.getUserProfile(this.token)

      }
      this.language = localStorage.getItem('language') ? localStorage.getItem('language') : 'fr';
      this.translate.use(this.language)
   }

   public setCartItemDefaultValue() {
      let products: any;
      products = JSON.parse(localStorage.getItem("cart_item")) || [];

      localStorage.setItem("cart_item", JSON.stringify(products));
      this.calculateLocalCartProdCounts();
   }

   public reviewPopup(singleProductDetails, reviews) {
      let review: MatDialogRef<ReviewPopupComponent>;
      const dialogConfig = new MatDialogConfig();
      if (this.isDirectionRtl) {
         dialogConfig.direction = 'rtl';
      } else {
         dialogConfig.direction = 'ltr';
      }

      review = this.dialog.open(ReviewPopupComponent, dialogConfig);
      review.componentInstance.singleProductDetails = singleProductDetails;
      review.componentInstance.reviews = reviews;

      return review.afterClosed();
   }

   public confirmationPopup(message: string) {
      let confirmationPopup: MatDialogRef<ConfirmationPopupComponent>;
      confirmationPopup = this.dialog.open(ConfirmationPopupComponent);
      confirmationPopup.componentInstance.message = message;

      return confirmationPopup.afterClosed();
   }

   // public getProducts() {
   //    this.products = this.db.object("products");
   //    return this.products;
   // }


   public getArticles() {

      return new Promise(resolve => {
         this.http.get(this.url + 'article', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }


   public getCountProducts() {

      return new Promise(resolve => {
         this.http.get(this.url + 'article/count', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }

         )
      });

   }



   public getCountSessions() {

      return new Promise(resolve => {
         this.http.get(this.url + 'session/count', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }

         )
      });

   }

   public getAllMasters() {

      return new Promise(resolve => {
         this.http.get(this.url + 'session/allMasters', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }

         )
      });

   }

   public getCountTraining() {

      return new Promise(resolve => {
         this.http.get(this.url + 'training/count', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }
         )
      });

   }


   public getArticlesPagination(limit, offset, search, priceLow, priceHigh, technic, shopPro) {


      let filters: any = {
         isPro: shopPro || 0,
         price_min: priceLow || null,
         price_max: priceHigh || null,
         technic_ids: technic || null,
         isVisible: 1,

      }


      const language = localStorage.getItem('language');

      language === 'fr' ? filters["name_fr"] = search || null : filters["name"] = search || null



      return new Promise(resolve => {

         this.http.post(this.url + 'article/filter?skip=' + offset + '&limit=' + limit, filters, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }


   public getSessionsAll(limit, offset, search, priceLow, priceHigh, technic, startDate, master, location) {

      let date
      let newEndDate
      startDate ? date = startDate : date = new Date().getFullYear() + "-01-01";



      if (startDate) {
         let endDate = new Date(date);
         endDate.setMonth(endDate.getMonth() + 1);
         newEndDate = this.formatDate(endDate.setDate(1));

      } else {
         newEndDate = new Date().getFullYear() + "-12-31"

      }

      return new Promise(resolve => {

         if (technic.length > 0) {

            this.http.get(this.url + 'session?filter[limit]=' +
               limit + '&filter[skip]=' + offset
               + '&filter[where][name][like]=%' + search + '%'
               + '&filter[where][master][like]=%' + master + '%'
               + '&filter[where][location][like]=%' + location + '%'
               + '&filter[where][startAt][between][0]=' + date
               + '&filter[where][startAt][between][1]=' + newEndDate
               + '&filter[where][priceTTC][between][0]=' + priceLow
               + '&filter[where][priceTTC][between][1]=' + priceHigh
               + '&filter[where][technicId][like]=' + technic, {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },

               err => {

                  resolve({ data: err });
               }

            )
         } else {

            this.http.get(this.url + 'session?filter[limit]=' +
               limit + '&filter[skip]=' + offset
               + '&filter[where][name][like]=%' + search + '%'
               + '&filter[where][master][like]=%' + master + '%'
               + '&filter[where][location][like]=%' + location + '%'
               + '&filter[where][startAt][between][0]=' + date
               + '&filter[where][startAt][between][1]=' + newEndDate
               + '&filter[where][priceTTC][between][0]=' + priceLow
               + '&filter[where][priceTTC][between][1]=' + priceHigh, {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },

               err => {

                  resolve({ data: err });
               }

            )
         }




      });

   }



   public getTrainingAll(limit, offset, lowPrice, hightPrice, search, technic) {


      if (hightPrice && lowPrice) {

         return new Promise(resolve => {
            this.http.get(this.url
               + 'training?filter[limit]=' + limit
               + '&filter[skip]=' + offset
               + '&filter[where][price][between][0]=' + lowPrice
               + '&filter[where][price][between][1]=' + hightPrice
               + '&filter[where][name][like]=%' + search
               + '&filter[include][Cover]', {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },

               err => {

                  resolve({ data: err });
               }

            )
         });


      } else {


         return new Promise(resolve => {
            this.http.get(this.url
               + 'training?filter[limit]=' + limit
               + '&filter[skip]=' + offset
               + '&filter[where][name][like]=%' + search
               + '&filter[include][Cover]', {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },

               err => {

                  resolve({ data: err });
               }

            )
         });


      }



   }


   getTraining() {


      return new Promise(resolve => {
         this.http.get(this.url + 'training', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }

         )
      });

   }


   getTrainingSections(id) {

      let querry = { "where": { "trainingId": id } }

      return new Promise(resolve => {
         this.http.get(this.url + 'TrainingSections?filter[include]=Questions&filter[include]=Courses&filter[where][trainingId]=' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }


   public getNewArticles() {

      let querry = { "limit": "6" };

      let date = new Date();
      date.setDate(date.getDate() - 30)

      let dateString = date.toISOString().split('T')[0];
      let querryDate = { "where": { "createdAt": { "gte": dateString } } }


      return new Promise(resolve => {
         this.http.get(this.url + 'article?filter=' + JSON.stringify(querry) + '&filter' + JSON.stringify(querryDate), {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }



   public getBeautyNewArticles() {




      // let querry = { "limit": "10" };

      let date = new Date();
      date.setDate(date.getDate() - 30)

      let dateString = date.toISOString().split('T')[0];
      //let querryDate = { "where": { "createdAt": { "gte": dateString } } }


      return new Promise(resolve => {
         this.http.get(this.url
            + 'article?filter[limit]=' + 10
            + '&filter[where][isPro]=' + false
            , {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },

               err => {

                  resolve({ data: err });
               }

            )
      });

   }



   public getNewArticlesTechnics(id) {



      let filters: any = {
         isNew: true,
         technic_ids: [id],
      }


      //articlesTechnics?filter[include]=Article&filter[limit]=12&filter[skip]=0&filter[where][name][like]=%%&filter[where][price_HT][between][0]=0&filter[where][price_HT][between][1]=1000&filter[where][isPro]=true&filter[where][technicId][like]=052ea6e3-7ed8-42ab-a225-3f3303fe9361

      // let querry = { "limit": "6" };

      // let date = new Date();
      // date.setDate(date.getDate() - 30)

      // let dateString = date.toISOString().split('T')[0];
      // let querryDate = { "where": { "createdAt": { "gte": dateString } } }

      return new Promise(resolve => {

         this.http.post(this.url + 'article/filter?skip=' + 0 + '&limit=' + 6, filters, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });



   }


   public getSessions() {

      let querry = { "limit": "10" };

      let date = new Date();
      date.setDate(date.getDate() - 30)

      let dateString = date.toISOString().split('T')[0];
      let querryDate = { "where": { "createdAt": { "gte": dateString } } }


      return new Promise(resolve => {
         this.http.get(this.url + 'session?filter=' + JSON.stringify(querry) + '&filter' + JSON.stringify(querryDate), {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }



   /*
      ----------  Cart Product Function  ----------
   */

   // Adding new Product to cart in localStorage
   public addToCart(data: any, type: any = "", kit?) {

      let products: any;
      products = JSON.parse(localStorage.getItem("cart_item")) || [];
      let productsLength = products.length;

      let toastOption: ToastOptions = {
         title: "Adding Product To Cart",
         msg: "Product adding to the cart",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };

      let found = products.some(function (el, index) {
         if (el.name == data.name) {
            if (!data.quantity) {
               data.quantity = 1;

               if (data.sessionId) {
                  data['is_width_kit'] = kit
               };

            }
            products[index]['quantity'] = data.quantity;

            if (data.sessionId) {
               products[index]['is_width_kit'] = kit
            };


            return true;
         }
      });

      if (!found) {
         data['quantity'] = 1;
         if (data.sessionId) {
            data['is_width_kit'] = kit
         };
         products.push(data);
      }

      if (productsLength == products.length) {
         toastOption.title = "Product Already Added";
         toastOption.msg = "You have already added this product to cart list";
      }

      if (type == 'wishlist') {
         this.removeLocalWishlistProduct(data);
      }

      this.toastyService.wait(toastOption);
      setTimeout(() => {
         localStorage.setItem("cart_item", JSON.stringify(products));
         this.calculateLocalCartProdCounts();
      }, 500);
   }

   public buyNow(data: any) {
      let products: any;
      products = JSON.parse(localStorage.getItem("cart_item")) || [];

      let found = products.some(function (el, index) {
         if (el.name == data.name) {
            if (!data.quantity) { data.quantity = 1 }
            products[index]['quantity'] = data.quantity;
            return true;
         }
      });
      if (!found) { products.push(data); }

      localStorage.setItem("cart_item", JSON.stringify(products));
      this.calculateLocalCartProdCounts();
   }

   public updateAllLocalCartProduct(products: any) {
      localStorage.removeItem('cart_item');

      localStorage.setItem("cart_item", JSON.stringify(products))
   }

   // returning LocalCarts Product Count
   public calculateLocalCartProdCounts() {
      this.localStorageCartProducts = null;
      this.localStorageCartProducts = JSON.parse(localStorage.getItem("cart_item")) || [];
      this.navbarCartCount = +((this.localStorageCartProducts).length);
   }

   // Removing cart from local
   public removeLocalCartProduct(product: any) {
      let products: any = JSON.parse(localStorage.getItem("cart_item"));
      localStorage.removeItem("cart_item");

      if (product.articleId) {

         for (let i = 0; i < products.length; i++) {
            if (products[i].articleId === product.articleId) {
               products.splice(i, 1);
               break;
            }
         }
      } else if (product.sessionId) {

         for (let i = 0; i < products.length; i++) {
            if (products[i].sessionId === product.sessionId) {
               products.splice(i, 1);
               break;
            }
         }

      } else if (product.trainingId) {

         for (let i = 0; i < products.length; i++) {
            if (products[i].trainingId === product.trainingId) {
               products.splice(i, 1);
               break;
            }
         }

      }





      let toastOption: ToastOptions = {
         title: "Remove Product From Cart",
         msg: "Product removing from cart",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };

      this.toastyService.wait(toastOption);
      setTimeout(() => {
         // ReAdding the products after remove
         localStorage.setItem("cart_item", JSON.stringify(products));
         this.calculateLocalCartProdCounts();
      }, 500);
   }

   /*
      ----------  Wishlist Product Function  ----------
   */

   // Adding new Product to Wishlist in localStorage
   public addToWishlist(data: any) {
      let toastOption: ToastOptions = {
         title: "Adding Product To Wishlist",
         msg: "Product adding to the wishlist",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };

      let products: any;
      products = JSON.parse(localStorage.getItem("wishlist_item")) || [];
      let productsLength = products.length;

      let found = products.some(function (el, index) {
         if (el.name == data.name) {
            if (!data.quantity) { data.quantity = 1 }
            products[index]['quantity'] = data.quantity;
            return true;
         }
      });
      if (!found) { products.push(data); }

      if (productsLength == products.length) {
         toastOption.title = "Product Already Added";
         toastOption.msg = "You have already added this product to wishlist";
      }

      this.toastyService.wait(toastOption);
      setTimeout(() => {
         localStorage.setItem("wishlist_item", JSON.stringify(products));
         this.calculateLocalWishlistProdCounts();
      }, 500);

   }

   // returning LocalWishlist Product Count
   public calculateLocalWishlistProdCounts() {

      this.localStorageWishlist = null;
      this.localStorageWishlist = JSON.parse(localStorage.getItem("wishlist_item")) || [];
      this.navbarWishlistProdCount = +((this.localStorageWishlist).length);
   }

   // Removing Wishlist from local
   public removeLocalWishlistProduct(product: any) {
      let products: any = JSON.parse(localStorage.getItem("wishlist_item"));

      for (let i = 0; i < products.length; i++) {
         if (products[i].productId === product.productId) {
            products.splice(i, 1);
            break;
         }
      }

      const toastOption: ToastOptions = {
         title: "Remove Product From Wishlist",
         msg: "Product removing from wishlist",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };


      this.toastyService.wait(toastOption);
      setTimeout(() => {
         // ReAdding the products after remove
         localStorage.setItem("wishlist_item", JSON.stringify(products));
         this.calculateLocalWishlistProdCounts();
      }, 500);

   }

   public addAllWishListToCart(dataArray: any) {
      let a: any;
      a = JSON.parse(localStorage.getItem("cart_item")) || [];

      for (let singleData of dataArray) {
         a.push(singleData);
      }

      let toastOption: ToastOptions = {
         title: "Adding All Product To Cart",
         msg: "Products adding to the cart",
         showClose: true,
         timeout: 1000,
         theme: "material"
      };

      this.toastyService.wait(toastOption);
      setTimeout(() => {
         localStorage.removeItem('wishlist_item');
         localStorage.setItem("cart_item", JSON.stringify(a));
         this.calculateLocalCartProdCounts();
         this.calculateLocalWishlistProdCounts();
      }, 500);

   }

   /**
    * getBlogList used to get the blog list. 
    */
   // public getBlogList() {
   //    let blogs: any;
   //    blogs = this.db.list("blogs");
   //    return blogs;
   // }

   /**
    * getContactInfo used to get the contact infomation. 
    */
   public getContactInfo() {
      let contact: any;
      contact = {
         address: '69 Avenue De Villiers, 75017, Paris, France',
         info: '',
         call: '+33 7 83 73 37 00',
         mail: 'Contact@fabulous.paris'


      }
      return contact;
   }

   /**
    * getTermCondition used to get the term and condition. 
    */
   // public getTermCondition() {
   //    let termCondition: any;
   //    termCondition = this.db.list("term_condition");
   //    return termCondition;
   // }

   /**
    * getPrivacyPolicy used to get the privacy policy.
    */
   // public getPrivacyPolicy() {
   //    let privacyPolicy: any;
   //    privacyPolicy = this.db.list("privacy_policy");
   //    return privacyPolicy;
   // }

   /**
    * getFaq used to get the faq.
    */

   // public getFaq() {
   //    let faq: any;
   //    faq = this.db.object("faq");
   //    return faq;
   // }

   /**
    * getProductReviews used to get the product review.
    */
   // public getProductReviews() {
   //    let review: any;
   //    review = this.db.list("product_reviews");
   //    return review;
   // }

   /**
    * Buy Product functions 
    */
   public addBuyUserDetails(formdata) {
      localStorage.setItem("user", JSON.stringify(formdata));

      let product = JSON.parse(localStorage.getItem("cart_item"))
      localStorage.setItem("byProductDetails", JSON.stringify(product));
      this.buyUserCartProducts = JSON.parse(localStorage.getItem("byProductDetails"))

      localStorage.removeItem("cart_item");
      this.calculateLocalCartProdCounts();
   }

   public removeBuyProducts() {
      localStorage.removeItem("byProductDetails");
      this.buyUserCartProducts = JSON.parse(localStorage.getItem("byProductDetails"))
   }

   /**
    * getTeam used to get the team data.
    */

   // public getTeam() {
   //    let team: any;
   //    team = this.db.list("team");
   //    return team;
   // }

   /**
    * getTestimonial used to get the testimonial data.
    */
   // public getTestimonial() {
   //    let testimonial: any;
   //    testimonial = this.db.object("testimonial");
   //    return testimonial;
   // }

   /**
    * getMissionVision used to get the Mission and Vision data.
    */
   // public getMissionVision() {
   //    let mission_vision: any;
   //    mission_vision = this.db.list("mission_vision");
   //    return mission_vision;
   // }

   /**
    * getAboutInfo used to get the about info data.
    */
   // public getAboutInfo() {
   //    let about_info: any;
   //    about_info = this.db.object("about_info");
   //    return about_info;
   // }


   public login(email: string, password: string) {
      return this.http.post<any>(this.url + 'user/login', { email: email, password: password })
         .map(user => {
            return user;
         });
   }


   public register(data) {
      return new Promise(resolve => {
         this.http.post(this.url + 'user/register', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }



   public getArticlesQuerry(queery) {

      return new Promise(resolve => {
         this.http.get(this.url + 'article?filter=' + queery, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }



   public getUserProfile(token) {

      this.getProfileUser(token).then(

         resp => {
            let response = resp['data'];
            if (response.status == 401) {
               localStorage.removeItem('currentUser');
               localStorage.removeItem('token');
               //window.location.reload();
            } else {
               this.UserProfile = resp['data'];
            }

         },
         error => {

            console.log(error)
         }

      )

   }

   public getProfileUser(id) {
      let token
      id ? token = id : token = this.token;

      return new Promise(resolve => {
         this.http.get(this.url + 'profile/profile?access_token=' + token, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }


   public getTechnics() {

      return new Promise(resolve => {
         this.http.get(this.url + 'technic?filter[order]=index%20ASC', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }

   public getSignleTechnics(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'technic/' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }


   getTechnicSessions(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'session?filter={"where":{"technicId":"' + id + '"}}', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }




   getTechnicProducts(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'ArticlesTechnics?filter={"include":["Article"],"where":{"technicId":"' + id + '"}}', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }

         )
      });




   }


   getDetailProduct(id) {


      return new Promise(resolve => {
         this.http.get(this.url + 'article/' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }
         )
      });

   }


   getDetailSession(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'session/' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }
         )
      });

   }


   getDetailTraining(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'training/' + id + '?filter={%22include%22:[%22Cover%22]}', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },
            err => {
               resolve({ data: err });
            }
         )
      });

   }


   getSectionCours(id) {


      // TrainingSectionCourses?filter={%22where%22:{%22trainingSectionId%22:5}}

      return new Promise(resolve => {
         this.http.get(this.url + 'TrainingSectionCourses?'
            + 'filter[where][trainingSectionId]=' + id
            + '&access_token=' + this.token
            , {
               headers: { 'Content-Type': 'application/json' },
               observe: 'response'
            }).subscribe(resp => {
               resolve({ data: resp.body });
            },
               err => {
                  resolve({ data: err });
               }
            )
      });
   }




   public updateProfile(data) {

      return new Promise(resolve => {
         this.http.patch(this.url + 'profile/editProfile?access_token=' + this.token, data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }

   getCurrentTax() {

      this.getTaxes().then(
         resp => {
            let response = resp['data']
            this.tax = Number(response.value);
         },
         error => {
            console.log(error)
         }

      )
   }


   getTechnicsList() {

      this.getTechnics().then(

         resp => {

            this.technics = resp['data'];

            this.technics.forEach(element => {
               let object = {
                  id: element.technicId,
                  name: element.name,
                  nameFr: element.name_fr
               }
               this.TechnicsTab.push(object)
            });
         },

         error => {

            console.log(error)
         }

      )
   }


   getPromoText() {


      this.getPromoBar().then(
         resp => {
            this.promoText = resp['data'][0];
         },
         error => {
            console.log(error)
         }
      )

   }

   NewOrder(data) {

      return new Promise(resolve => {
         this.http.post(this.url + 'orders/new', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });
   }


   PayOrder(data) {
      return new Promise(resolve => {
         this.http.post(this.url + 'orders/checkout/payOrder', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }

   getUserOrders(id) {

      // let querry = { "order": 'createdAt DESC' }
      return new Promise(resolve => {
         this.http.get(this.url + 'orders?&filter[where][userId]=' + id + '&filter[order]=createdAt%20DESC', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }



   getUserTrainings(pageSize, offset) {


      return new Promise(resolve => {
         this.http.get(this.url + 'training/purchased?skip=' +
            offset + '&limit=' + pageSize + '&access_token=' + this.token, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body });
         },

            err => {

               resolve({ data: err });
            }

         )
      });

   }

   getOrderDetail(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'orders/articles?orderId=' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });
   }

   getOneOrder(id) {

      return new Promise(resolve => {
         this.http.get(this.url + 'orders/' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }

   getOrderTrainings(id) {

      //OrdersArticles?filter={"include":["Training"],"where":{"orderId":"49d1dd7b-44d4-4ec5-9d63-71abd9ef6ee9"}}

      let querry = { "include": ["Training"], "where": { "orderId": id } }

      return new Promise(resolve => {
         this.http.get(this.url + 'orders/trainings?orderId=' + id, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }


   cancelOrder(id) {
      let data = {
         status: 4
      }

      return new Promise(resolve => {
         this.http.patch(this.url + 'orders/' + id, data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }


   getTaxes() {

      return new Promise(resolve => {
         this.http.get(this.url + 'taxes/findByStatus?status=1', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });
   }


   SocialSignIn(data) {

      return new Promise(resolve => {
         this.http.post(this.url + 'user/providerauth', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }



   SendNewConsent(data) {

      return new Promise(resolve => {
         this.http.post(this.url + 'consent/newConsent?access_token=' + this.token, JSON.stringify(data), {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }

   contactUs(data) {

      return new Promise(resolve => {
         this.http.post(this.url + 'contact', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });

   }



   getPromoBar() {



      return new Promise(resolve => {
         this.http.get(this.url + 'promoBar?filter[order]=createdAt%20DESC', {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });


   }


   getCountriesApi() {



      return new Promise(resolve => {
         this.http.get('https://restcountries.eu/rest/v2/all?limit=250', {
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });



   }


   HandleShippers(data) {

      return new Promise(resolve => {
         this.http.post(this.BoxtalApi + 'get_cotation.php', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });


   }


   formatDate(date) {
      var d = new Date(date),
         month = '' + (d.getMonth() + 1),
         day = '' + d.getDate(),
         year = d.getFullYear();

      if (month.length < 2)
         month = '0' + month;
      if (day.length < 2)
         day = '0' + day;

      return [year, month, day].join('-');
   }

   verifyReferralCode(code) {

      return new Promise(resolve => {
         this.http.get(this.url + 'Profile/checkRefCode?ref_code=' + code, {
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });


   }


   getPromoCode(code) {

      return new Promise(resolve => {
         this.http.get(this.url + 'Promotion?filter[where][code]=' + code + '&filter[where][is_active]=true', {
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });



   }



   getReferralCodeReduction() {


      return new Promise(resolve => {
         this.http.get(this.url + 'configreduction?filter[order]=createdAt%20DESC', {
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });


   }


   getOrderPrice(data) {

      return new Promise(resolve => {
         this.http.post(this.url + 'orders/orderAmounts', data, {
            headers: { 'Content-Type': 'application/json' },
            observe: 'response'
         }).subscribe(resp => {
            resolve({ data: resp.body, status: resp.status });
         },
            error => {
               resolve({ data: error, error: status })
            }
         )
      });



   }

}
