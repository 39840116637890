import { Injectable } from '@angular/core';

/*
 * Menu interface
 */
export interface Menu {
	state: string;
	name?: string;
	type?: string;
	icon?: string;
	children?: Menu[];
}

const HeaderOneItems= [
  // {
  //   state:'',
  //   name:"",
  //   type:"link",
  //   icon: 'home'
  // },
  {
    state: "technics",
    name : "Academy",
    type: "link",
    // children: [
    //   {
    //     state: '',
    //     name: 'HYALURONIC PEN',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: '',
    //     name: 'BB Glow et Microneedling',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: '',
    //     name: 'Plasma pen',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: '',
    //     name: 'Fabulous spin',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: '',
    //     name: 'Manual permanent',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: '',
    //     name: 'Hair line',
    //     type: 'link',
    //     icon: ''
    //   },
    // ]
  },
  {
    state: "products",
    name : "Pro-Shop",
    queryState:'shop-pro',
    type: "queryParams",
    icon: "",
    // children: [
    //   {
    //     state: 'products/hyaluronic-pen',
    //     name: 'Hyaluronic pen',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: 'products/bb-glow',
    //     name: 'Bb-glow',
    //     type: 'link',
    //     icon: ''
    //   },
    //   {
    //     state: 'products/microneedling',
    //     name: 'Microneedling',
    //     type: 'link',
    //     icon: ''
    //   },
    // ]
  },

  {
    state: "products",
    name : "Shop",
    queryState:'shop',
    type: "queryParams",
    icon: "",
  },


  // {
  //   state:'products',
  //   name:"CATEGORIES",
  //   type:"sub",
  //   mega:true,
  //   icon: 'party_mode',
  //   children: [
  //     {
  //       state: 'men',
  //       name: 'MEN',
  //       type: 'sub',
  //       icon: 'arrow_right_alt',
  //       children:[
  //         {
  //           state: 'products/men',
  //           queryState:'Jeans',
  //           name: 'JEAN',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/men',
  //           queryState:'Jackets',
  //           name: 'JACKETS',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/men',
  //           queryState:'Shirt',
  //           name: 'SHIRT',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/men',
  //           queryState:'T-Shirt',
  //           name: 'T-SHIRT',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         }
  //       ]
  //     },
  //     {
  //       state: 'woman',
  //       name: 'WOMEN',
  //       type: 'sub',
  //       icon: 'arrow_right_alt',
  //       children:[
  //         {
  //           state: 'products/woman',
  //           queryState:'Dresses',
  //           name: 'DRESS',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/woman',
  //           queryState:'Shirt',
  //           name: 'SHIRT',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/woman',
  //           queryState:'T-Shirt',
  //           name: 'T-SHIRT',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         }
  //       ]
  //     },
  //     {
  //       state: 'gadgets',
  //       name: 'GADGETS',
  //       type: 'sub',
  //       icon: 'arrow_right_alt',
  //       children:[
  //         {
  //           state: 'products/gadgets',
  //           queryState:'Headphone',
  //           name: 'HEADPHONE',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/gadgets',
  //           queryState:'Smartphone',
  //           name: 'SMARTPHONE',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/gadgets',
  //           queryState:'Watch',
  //           name: 'WATCH',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         },
  //         {
  //           state: 'products/gadgets',
  //           queryState:'Speaker',
  //           name: 'SPEAKER',
  //           type: 'queryParams',
  //           icon: 'arrow_right_alt',
  //         }
  //       ]
  //     },
      {
        state: 'consentement',
        name: 'Consentements',
        type: 'sub',
        icon: '',
    //     children:[
    //       {
    //         state: 'consentement',
    //         queryState:'bbglow',
    //         name: 'BB GLOW & MICRONEEDLING',
    //         type: 'queryParams',
    //         icon: ''
    //       },
    //       {
    //         state: 'consentement',
    //         name: 'PLASMA PEN',
    //         queryState:'plasmapen',
    //         type: 'queryParams',
    //         icon: ''
    //       },
    //       {
    //         state: 'consentement',
    //         name: 'MICROBLADING OU SPIN',
    //         queryState:'microbladingspin',
    //         type: 'queryParams',
    //         icon: ''
    //       },     
    // ]
  },
//   {
//   state: "pages",
//   name: "PAGES",
//   type: "sub",
//   icon: "pages",
//   children: [
//      {  state: 'about',
//         name: 'ABOUT',
//         type: 'link',
//         icon: 'arrow_right_alt',
//      },
//      {  state: 'term-condition',
//         name: 'TERM AND CONDITION',
//         type: 'link',
//         icon: 'arrow_right_alt',
//      },
//      {
//         state: 'privacy-policy',
//         name: 'PRIVACY POLICY',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {
//         state: 'blogs/detail',
//         name: 'BLOG DETAIL',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {
//         state: 'faq',
//         name: 'FAQ',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {
//         state: 'not-found',
//         name: '404 PAGE',
//         type: 'link',
//         icon: 'arrow_right_alt',
//       },
//       {
//          state: 'account/profile',
//          name: 'User Profile',
//          type: 'link',
//          icon: 'arrow_right_alt',
//        }
//     ]
// },
// {
//     state:'session',
//     name:"SESSION",
//     type:"sub",
//     icon: 'supervised_user_circle',
//     children: [
//         {
//         state: 'session/signin',
//         name: 'SIGN IN',
//         type: 'link',
//         icon: 'arrow_right_alt',
//         },
//         {
//             state: 'session/signup',
//             name: 'REGISTER',
//             type: 'link',
//             icon: 'arrow_right_alt',
//         },
//         {
//             state: 'session/forgot-password',
//             name: 'FORGET PASSWORD',
//             type: 'link',
//             icon: 'arrow_right_alt',
//         },
//         {
//             state: 'session/thank-you',
//             name: 'THANK YOU',
//             type: 'link',
//             icon: 'arrow_right_alt',
//         }
//     ]
//   },


  {
    state:'products',
    name:"Online",
    queryState:'trainings',
    type: 'queryParams',
    icon: ''
  },

  {
    state:'contact',
    name:"Contact us",
    type:"link",
    icon: ''
  },
  {
    state:'https://widget.treatwell.fr/salon/fabulous-paris/?utm_source=partner&utm_medium=partner-site-book-now-widget',
    name:"Réserver mon soin",
    type:"external-link",
    icon: ''
  }
];

const FooterOneItems= [
  {
     state:'',
     name:"ABOUT",
     type:"sub",
     icon: '',
     children: [
      {
         state: '',
         name: 'ABOUT',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {
         state: '',
         name: 'TERM AND CONDITION',
         type: 'link',
         icon: 'arrow_right_alt',
      },
      {
        state: '',
        name: 'PRIVACY POLICY',
        type: 'link',
        icon: 'arrow_right_alt',
      },
      {
        state: '',
        name: 'FAQ',
        type: 'link',
        icon: 'arrow_right_alt',
       },
       {
         state:'contact',
         name:"CONTACT US",
         type:"link",
         icon: 'perm_contact_calendar',
       }
    ]
  },
  // {
  //   state:'',
  //   name:"SESSION",
  //   type:"sub",
  //   icon: '',
  //   children: [
  //       {
  //       state: 'session/signin',
  //       name: 'SIGN IN',
  //       type: 'link',
  //       icon: 'arrow_right_alt',
  //       },
  //       {
  //           state: 'session/signup',
  //           name: 'REGISTER',
  //           type: 'link',
  //           icon: 'arrow_right_alt',
  //       },
  //       {
  //           state: 'session/forgot-password',
  //           name: 'FORGET PASSWORD',
  //           type: 'link',
  //           icon: 'arrow_right_alt',
  //       },
  //       {
  //           state: 'session/thank-you',
  //           name: 'THANK YOU',
  //           type: 'link',
  //           icon: 'arrow_right_alt',
  //       }
  //   ]
  // },
  // {
  //   state:'',
  //   name:"CATEGORIES",
  //   type:"sub",
  //   icon: '',
  //   children: [
  //     {
  //       state: 'products/women',
  //       name: 'WOMEN',
  //       type: 'link',
  //       icon: 'arrow_right_alt',
  //     },
  //     {
  //       state: 'products/men',
  //       name: 'MEN',
  //       type: 'link',
  //       icon: 'arrow_right_alt',
  //     },
  //     {
  //       state: 'products/accesories',
  //       name: 'ACCESSORIES',
  //       type: 'link',
  //       icon: 'arrow_right_alt',
  //     },
  //     {
  //       state: 'products/gadgets',
  //       name: 'GADGETS',
  //       type: 'link',
  //       icon: 'arrow_right_alt',
  //     }
  //   ]
  // },
  {
    state:'',
    name:"SOCIAL",
    type:"sub",
    icon: '',
    children: [
      {
        state: 'https://www.facebook.com/FabulousParisSarah/',
        name: 'Facebook',
        type: 'social_link',
        icon: 'arrow_right_alt',
      },
      {
        state: 'https://www.youtube.com/channel/UCY4QaEDneYKj-wPVutRvgwg',
        name: 'Youtube',
        type: 'social_link',
        icon: 'arrow_right_alt',
      }
    ]
  }

]

@Injectable()
export class MenuItems {

   /*
    * Get all header menu
    */
   getMainMenu(): Menu[] {
      return HeaderOneItems;
   }

   /*
    * Get all footer menu
    */
   getFooterOneMenu(): Menu[] {
      return FooterOneItems;
   }
}
