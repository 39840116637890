import { environment } from '../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
@Component({
  selector: 'embryo-NewProductsCard',
  templateUrl: './NewProductsCard.component.html',
  styleUrls: ['./NewProductsCard.component.scss']
})
export class NewProductsCardComponent implements OnInit {


   ImagesUrl : any;

   currentLanguage= 'en'

   @Input() product : any;

   @Input() currency : string;

   @Input() type : string;

   @Output() addToCart: EventEmitter<any> = new EventEmitter();

   @Output() addToWishlist: EventEmitter<any> = new EventEmitter();

   constructor(public translate: TranslateService) { 

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })


   }

   ngOnInit() {
 
      this.type =='sessions' ? this.ImagesUrl = environment.imageSessioncUrl : this.ImagesUrl= environment.imageArticlecUrl ;

      
      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
     });
     
   }

   public addToCartProduct(value:any) {
      this.addToCart.emit(value);
   }

   public productAddToWishlist(value:any) {
      this.addToWishlist.emit(value);
   }


   public checkCartAlready(singleProduct) {
      let products = JSON.parse(localStorage.getItem("cart_item")) || [];
      if (!products.some((item) => item.name == singleProduct.name)) {
         return true;
      }
   }


   showPrice(Price,tax){
      let PriceTTC = 0;
      let _tax = Number(tax) || 0 ;
      let _price = Number(Price) || 0 ;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC =  (( _price * _tax / 100 ) + _price ) ;
      } catch (error) {
         PriceTTC = 0
      }
      return PriceTTC;

   }


   public showDate(StartDate, EndDate) {

      let date = ''
      const startDay = new Date(StartDate).getDate();
      const endDay = new Date(EndDate).getDate();

      const startMonth = new Date(StartDate).getMonth() + 1;
      const endMonth = new Date(EndDate).getMonth() + 1;

      const startYear = new Date(StartDate).getFullYear();
      const endYear = new Date(EndDate).getFullYear();

      if (startYear === endYear) {

         if (startMonth === endMonth) {

            date = startDay + ' - ' + endDay + '/' + startMonth + '/' + startYear;
         } else {

            date = startDay + '/' + startMonth + ' - ' + endDay + '/' + endMonth + '-' + startYear;
         }

      } else {

         date = startDay + '/' + startMonth + '/' + startYear + ' - ' + endDay + '/' + endMonth + '/' + endYear;
      }

      return date;

   }


}
