import { Component, OnInit } from '@angular/core';
import { EmbryoService } from '../../../Services/Embryo.service';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
@Component({
   selector: 'embryo-FixedHeader',
   templateUrl: './FixedHeader.component.html',
   styleUrls: ['./FixedHeader.component.scss']
})
export class FixedHeaderComponent implements OnInit {


   popupResponse: any;

   promoText: any;
   currentLanguage: any;

   constructor(
      public translate: TranslateService,
      public embryoService: EmbryoService) {

      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr";

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })


   }

   ngOnInit() {

      this.promoText = this.embryoService.promoText;

      if (this.promoText == undefined || this.promoText == null) {

         this.embryoService.getPromoBar().then(
            resp => {
               this.promoText = resp['data'][0];
            },
            error => {
               console.log(error)
            }
         )


      }

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
      });



   }

   public toggleSidebar() {
      this.embryoService.sidenavOpen = !this.embryoService.sidenavOpen;
   }


   public openConfirmationPopup(value: any) {
      let message = "Are you sure you want to delete this product?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => { this.popupResponse = res },
            err => console.log(err),
            () => this.getPopupResponse(this.popupResponse, value, 'cart')
         );
   }


   public getPopupResponse(response: any, value: any, type) {
      if (response) {
         if (type == 'cart') {
            this.embryoService.removeLocalCartProduct(value);
         } else {
            this.embryoService.removeLocalWishlistProduct(value);
         }
      }
   }


}
