import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'embryo-CollectionLevels',
  templateUrl: './CollectionLevels.component.html',
  styleUrls: ['./CollectionLevels.component.scss']
})
export class CollectionLevelsComponent implements OnInit {


  levels: any = [

    {
      name : 'FABULOUS BABY',
      image : 'https://i0.wp.com/www.fabulous.paris/wp-content/uploads/2019/12/BabyFabulous.png?w=885&ssl=1'
    },

    {
      name : 'FABULOUS ARTIST',
      image : 'https://i1.wp.com/www.fabulous.paris/wp-content/uploads/2019/12/ArtistFabulous.png?w=885&ssl=1'
    },

    {
      name : 'FABULOUS EXPERT',
      image : 'https://i0.wp.com/www.fabulous.paris/wp-content/uploads/2019/12/SilverFabulous.png?w=885&ssl=1'
    },
    {
      name : 'FABULOUS MASTER',
      image : 'https://i1.wp.com/www.fabulous.paris/wp-content/uploads/2019/12/GoldFabulous.png?w=885&ssl=1'
    },

    {
      name : 'GRAND FABULOUS MASTER',
      image : 'https://i0.wp.com/www.fabulous.paris/wp-content/uploads/2019/12/Grand_Fabulous.png?w=885&ssl=1'
    }

  ]


  constructor(
    public translate: TranslateService

  ) { }

  ngOnInit() {
  }

}
