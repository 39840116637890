import { environment } from '../../../environments/environment';
import { Component, OnInit,ViewChild } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';

import { EmbryoService } from '../../Services/Embryo.service';

@Component({
  selector: 'embryo-PaymentDetailSideBar',
  templateUrl: './PaymentDetailSideBar.component.html',
  styleUrls: ['./PaymentDetailSideBar.component.scss']
})
export class PaymentDetailSideBarComponent implements OnInit {

   cartProducts  : any;
   popupResponse : any;

   currentLanguage = 'en'

   order: any;
   OrderResponse: any;

   ImagesArticlesUrl = environment.imageArticlecUrl; 
   ImagesSessionsUrl = environment.imageSessioncUrl;

   constructor(public embryoService: EmbryoService,
               private loadingBar: LoadingBarService) { 

          localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"
         }

   ngOnInit() {

      
      this.order = {
         "currency": "eur",
         "articles": [],
         "sessions": [],
         "trainings": [],
         "use_fidelities": false,
         "use_balance" : false,
      }


      this.prepareOrder()
      
   }

   public calculateTotalPrice() {
      let subtotal = 0;
      if(this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length>0) {
         for(let product of this.embryoService.localStorageCartProducts) {

            if ( product.price_ttc) {
               subtotal +=  product.price_ttc * product.quantity
            } else if (product.priceTTC) {
               subtotal +=  product.priceTTC * product.quantity
            } else {
               subtotal += product.price_ttc * product.quantity
            }
         }
      }
      return subtotal;
   }


   prepareOrder(){
   
      const Settings = JSON.parse(localStorage.getItem('OrderParams'));

      let products = this.embryoService.localStorageCartProducts;

      let orderIdProduct: any = []
      let orderSessions: any = [];
      let orderTrainings: any = [];

      products.forEach(element => {

         if (element.articleId) {
            let product = {
               articleId: element.articleId,
               quantity: element.quantity,
               name: element.name,
               price: element.price_ttc || 0
            }
            orderIdProduct.push(product)

         } else if (element.sessionId) {

            let sessions = {
               sessionId: element.sessionId,
               quantity: element.quantity,
               name: element.name,
               is_width_kit : element.is_width_kit || false,
               price: element.priceTTC || 0
            }
            orderSessions.push(sessions)
         }

         else if (element.trainingId) {

            let training = {
               trainingId: element.trainingId,
               name: element.name,
               price: element.price_ttc || 0
            }
            orderTrainings.push(training)
         }

      });


      this.order['articles'] = orderIdProduct;
      this.order['sessions'] = orderSessions;
      this.order['trainings'] = orderTrainings;
      this.order['ref_code'] = Settings ? Settings.ref_code : ''
      this.order['use_balance'] = Settings ?  Settings.use_balance : ''
      this.order['promotion'] = Settings ?  Settings.promotion : ''

      this.embryoService.getOrderPrice(this.order).then(
         resp => {
               this.OrderResponse = resp['data'];
         },
         error => {
            console.log(error);

         }
      )




   }

   public removeProduct(value) {
      let message = "Are you sure you want to delete this product?";
      this.embryoService.confirmationPopup(message).
         subscribe(res => {this.popupResponse = res},
                   err => console.log(err),
                   ()  => this.getPopupResponse(this.popupResponse, value)
                  );
   }

   public getPopupResponse(response, value) {
      if(response){
         this.embryoService.removeLocalCartProduct(value);
         this.embryoService.paymentSidenavOpen = false;
      }
   }

   public calculateProductSinglePrice(product:any, value: any) {
      let price = 0;
      product.quantity = value;

      if ( product.price_ttc) {
         price +=  product.price_ttc * product.quantity
      } else if (product.priceTTC) {
         price +=  product.priceTTC * product.quantity
      } else {
         price += product.price_ttc * product.quantity
      }
      return price;
   }

   public getTotalPrice() {
      let total = 0;
      if(this.embryoService.localStorageCartProducts && this.embryoService.localStorageCartProducts.length>0) {
         for(let product of this.embryoService.localStorageCartProducts) {
            if ( product.price_ttc) {
               total +=  product.price_ttc * product.quantity
            } else if (product.priceTTC) {
               total +=  product.priceTTC * product.quantity
            } else {
               total += product.price_ttc * product.quantity
            }
         }
         
      }
      return total;
   }

   CalculePrice(Price,tax,quantity){

      let PriceTTC = 0;
      let _tax = Number(tax) || 0 ;
      let _price = Number(Price) || 0 ;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC =  (( _price * _tax / 100 ) + _price ) *quantity ;
      } catch (error) {
         PriceTTC = 0
      }
      return PriceTTC;
   }


}
