import { Component, OnInit } from '@angular/core';
import { EmbryoService } from '../../../Services/Embryo.service';
import { Meta, Title } from "@angular/platform-browser";
@Component({
  selector: 'app-HomeThree',
  templateUrl: './HomeThree.component.html',
  styleUrls: ['./HomeThree.component.scss']
})
export class HomeThreeComponent implements OnInit {

   categories : any = {
      clothing     : [],
      shoes        : [],
      accessories  : [],
      gadgets      : []
   }
   products : any;
   allProducts : any;
   newProductsSliderData : any;

   sessions:any;
   technics : any;

   articlesBeauty: any;

   TechnicsTab: any = [];
   TechnicIdSelected : '';


   loading = false;


   sessionConfig = {
      slidesToShow: 3,
      slidesToScroll:1,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: true,
      nextArrow: '<button class="mat-raised-button mat-accent accent next"><span class="material-icons"> keyboard_arrow_right </span></button>',
      prevArrow: '<button class="mat-raised-button mat-accent accent prev"><span class="material-icons"> keyboard_arrow_left </span></button>',
      responsive: [
         {
            breakpoint: 992,
            settings: {
               arrows: true,
               slidesToShow: 2,
               slidesToScroll:1
            }
            },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll:1
            }
            },
         {
            breakpoint: 480,
            settings: {
               arrows: false,
               slidesToShow: 1,
               slidesToScroll:1
            }
         }
      ]
   };


   slideConfig = {
      slidesToShow: 4,
      slidesToScroll:2,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: true,
      nextArrow: '<button class="mat-raised-button mat-accent accent next"><span class="material-icons"> keyboard_arrow_right </span></button>',
      prevArrow: '<button class="mat-raised-button mat-accent accent prev"><span class="material-icons"> keyboard_arrow_left </span></button>',
      responsive: [
         {
            breakpoint: 992,
            settings: {
               arrows: true,
               slidesToShow: 2,
               slidesToScroll:1
            }
            },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll:1
            }
            },
         {
            breakpoint: 480,
            settings: {
               arrows: false,
               slidesToShow: 1,
               slidesToScroll:1
            }
         }
      ]
   };

   SlideProductsConfig = {
      slidesToShow: 4,
      slidesToScroll:4,
      autoplay: true,
      autoplaySpeed: 2000,
      dots: false,
      arrows: true,
      nextArrow: '<button class="mat-raised-button mat-accent accent next"><span class="material-icons"> keyboard_arrow_right </span></button>',
      prevArrow: '<button class="mat-raised-button mat-accent accent prev"><span class="material-icons"> keyboard_arrow_left </span></button>',
      responsive: [
         {
            breakpoint: 992,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll:2
            }
            },
         {
            breakpoint: 768,
            settings: {
               arrows: false,
               slidesToShow: 2,
               slidesToScroll:1
            }
            },
         {
            breakpoint: 480,
            settings: {
               arrows: false,
               slidesToShow: 1,
               slidesToScroll:1
            }
         }
      ]
   };

   constructor(
      meta: Meta, title: Title,
      public embryoService : EmbryoService
      ) { 
         
         this.technics= embryoService.technics;
         this.TechnicsTab = embryoService.TechnicsTab;
         title.setTitle('Fabulous Paris');

      }

   ngOnInit() {
      
      
      this.getSessions();
      this.getBeautyProducts();
      
   }


   ngAfterViewInit(){
      if ( this.technics == undefined) {
        this.embryoService.getTechnics().then(
          resp=>{
            this.technics= resp['data'];
            this.getProducts();
          }
        )
  
      } else {
         this.getProducts();
      }
  
    }

   public getProducts() {

     
      this.loading = true;

     // this.products = [];

      if (this.TechnicIdSelected !== undefined && this.TechnicIdSelected !== '' ) {

         this.embryoService.getNewArticlesTechnics(this.TechnicIdSelected).then(

            resp => {

               this.loading = false;
               this.products = [];
               let response = resp['data']['articles'] ;
               this.products = response
               
            },
            error => {
               this.loading = false;
               console.log(error);
   
            }


         )
      } else {
         this.embryoService.getNewArticlesTechnics(this.technics[0].technicId).then(
            resp => {
               this.loading = false;
               this.products = [];
               let response = resp['data']['articles'] ;
               this.products = response
              
            },
            error => {
               this.loading = false;
               console.log(error);
   
            }
         )
      }
      
   }

   public getBeautyProducts(){

      this.embryoService.getBeautyNewArticles().then(

         resp => {
            this.articlesBeauty = resp['data'];
         },
         error => {
            console.log(error);

         }

      )

   }

   public getSessions(){

      this.embryoService.getSessions().then(

         resp => {
            this.sessions = resp['data'];
         },
         error => {
            console.log(error);

         }

      )
   }

   // public getProductsResponse(res) {
   //    this.products = res;
   //    this.allProducts = ((res.men.concat(res.women)).concat(res.gadgets)).concat(res.accessories);
     
   //    for(let product of this.allProducts) {
   //       switch (product.category_type) {
   //          case "clothing":
   //             this.categories.clothing.push(product);
   //             break;

   //          case "shoes":
   //             this.categories.shoes.push(product);
   //             break;

   //          case "accessories":
   //             this.categories.accessories.push(product);
   //             break;

   //          case "gadgets":
   //             this.categories.gadgets.push(product);
   //             break;
            
   //          default:
   //             // code...
   //             break;
   //       }
   //    }
   // }

   // public onNewArrivalsSelectedTab(tabIndex) {

   //    console.log(tabIndex);

   //    this.newProductsSliderData = null;
   //    switch (tabIndex) {
   //       case 0:
   //          this.newProductsSliderData = this.allProducts;
   //       break;

   //       case 1:
   //          this.newProductsSliderData = this.products.men;
   //       break;

   //       case 2:
   //          this.newProductsSliderData = this.products.women;
   //       break;

   //       case 3:
   //          this.newProductsSliderData = this.products.gadgets;
   //       break;
         
   //       default:
   //          // code...
   //          break;
   //    }

   //    return true;
   // }

   public addToCart(value) {
      this.embryoService.addToCart(value);
   }

   public addToWishlist(value) {
      this.embryoService.addToWishlist(value);
   }

   public onTabChanged(index){

      let tablabel = index.tab.textLabel
      let indexTab = index.index;

      if (indexTab < (this.TechnicsTab.length + 1) &&  tablabel !== "Shop" ) {

         this.TechnicIdSelected = this.TechnicsTab[index.index]['id']

      } else {
         this.TechnicIdSelected = ''
      }


      this.getProducts();

   }



}
