import { environment } from '../../../environments/environment';
import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'embryo-HeaderCart',
  templateUrl: './HeaderCart.component.html',
  styleUrls: ['./HeaderCart.component.scss']
})
export class HeaderCartComponent implements OnInit, OnChanges {

   @Input() cartProducts : any;
   @Input() count        : any;
   @Input() currency     : string;

   mobWidth : any;
   mobScreenSize : number = 767;

   currentLanguage= 'en' 

   imagesUrls = environment.imageArticlecUrl
   sessionsUrl = environment.imageSessioncUrl;

   @Output() removeProductData : EventEmitter<any> = new EventEmitter();  

   hiddenBadge = true;

   constructor(public translate: TranslateService) {
      this.mobWidth = window.screen.width;

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })
   }

   ngOnInit() {

      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
     });


   }

   ngOnChanges() {
      if(this.count && this.count != 0) {
         this.hiddenBadge = false;
      } else {
         this.hiddenBadge = true;
      }
   }

   public confirmationPopup(product:any) {
      this.removeProductData.emit(product);
   }

   public calculatePrice(product) {
      let total = null;

      if( product.price_ttc ) {
         total = product.price_ttc * product.quantity;
      } else if (product.priceTTC){
         total = product.priceTTC * product.quantity;
      } else if ( product.price ) {
         total = product.price_ttc *product.quantity;
      }
      return total;
   }


   CalculePrice(Price,tax,quantity){
     // let PriceTTC = 0;


      let PriceTTC = 0;
      let _tax = Number(tax) || 0 ;
      let _price = Number(Price) || 0 ;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC =  (( _price * _tax / 100 ) + _price ) *quantity ;
      } catch (error) {
         PriceTTC = 0
      }

      

      return PriceTTC;

      

      // try {
      //    PriceTTC =  ((parseFloat(Price)*parseFloat(tax)) + parseFloat(Price))*quantity;
      // } catch (error) {
      //    console.log(error)
      // }
      // return PriceTTC;
   }

}
