import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'embryo-HeaderUserProfileDropdown',
  templateUrl: './HeaderUserProfileDropdown.component.html',
  styleUrls: ['./HeaderUserProfileDropdown.component.scss']
})
export class HeaderUserProfileDropdownComponent implements OnInit {

   user : any;
   constructor(
    private router: Router,
    public translate: TranslateService
   ) { }

   ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('currentUser'));
   }



   logout() {

      localStorage.removeItem('currentUser');
      localStorage.removeItem('token');
      window.location.reload();

   }

}
