import { EmbryoService } from '../../Services/Embryo.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';


import { MatDialog } from '@angular/material';

@Component({
  selector: 'embryo-SignIn',
  templateUrl: './CommonSignIn.component.html',
  styleUrls: ['./CommonSignIn.component.scss'],
  providers: [EmbryoService]
})
export class CommonSignInComponent implements OnInit {


  model: any = {};
  error = '';
  loading = false;
  returnUrl: string;
  user: any;
  socialUser: SocialUser;

  StorageExireDate : any ;
  ExpireDate: any ;

  hide = true;

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private embryoService: EmbryoService,
    public dialog: MatDialog,
    private router: Router,
    public translate: TranslateService,

  ) { }

  ngOnInit() {


    this.ExpireDate = new Date()
    this.ExpireDate.setHours(this.ExpireDate.getHours()+4); 


    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.user = JSON.parse(localStorage.getItem('currentUser'));
    this.StorageExireDate = localStorage.getItem('expireAt');

    // console.log(this.StorageExireDate);


    if (this.user && this.user.token != '') {

      this.router.navigate([this.returnUrl])
    }

    this.authService.authState.subscribe((user) => {
      this.socialUser = user;
    });


  }


  singin() {

    this.loading = true;

    this.embryoService.login(this.model.email, this.model.password).subscribe(
      resp => {
        this.loading = false;
        
        localStorage.setItem('token', resp.id);
        localStorage.setItem('currentUser', JSON.stringify(resp));
        localStorage.setItem('expireAt', this.ExpireDate)

        setTimeout(() => {
          window.location.reload();
        }, 1000);

      },
      err => {
        console.log(err)
        if (err.status == 401) {
          this.error = 'email ou mot de passe incorrect';
        } else {

          this.error = "une erreur est survenue veuillez réessayer";

        }

        setTimeout(() => {
          this.error = "";
        }, 3000);

        this.loading = false;


      }
    )

  }


  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(

      resp => {
        let data = {
          username: resp.email.slice(0, resp.email.indexOf('@')),
          name: resp.name,
          provider: 2,
          providerAvatarURL: resp.photoUrl

        }

        this.embryoService.SocialSignIn(data).then(
          resp => {

            let data: any = resp['data']

            if (resp['status'] == 200) {

              localStorage.setItem('token', data.token.id);
              localStorage.setItem('currentUser', JSON.stringify(data.token));
              localStorage.setItem('expireAt', this.ExpireDate)

              setTimeout(() => {
                window.location.reload();
              }, 1000);

            }

          },
          error => {
            console.log(error)
          }
        )

      }

    );
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then(

      resp => {
        let data = {
          username: resp.id,
          name: resp.name,
          provider: 1,
          providerAvatarURL: resp.photoUrl

        }

        this.embryoService.SocialSignIn(data).then(
          resp => {
            let data: any = resp['data']
            if (resp['status'] == 200) {

              localStorage.setItem('token', data.token.id);
              localStorage.setItem('currentUser', JSON.stringify(data.token));
              localStorage.setItem('expireAt', this.ExpireDate)

              setTimeout(() => {
                window.location.reload();
              }, 1000);

            }
          },
          error => {
            console.log(error)
          }
        )
      }

    );
  }



  signOut(): void {
    this.authService.signOut();
  }




  formateDate(date) {
    const formatted_date = date.getFullYear() + '-' +
      (this.appendLeadingZeroes(date.getMonth() + 1)) + '-' +
      this.appendLeadingZeroes(date.getDate()) + ' ' +
      this.appendLeadingZeroes(date.getHours()) + ':' +
      this.appendLeadingZeroes(date.getMinutes()) + ':' +
      this.appendLeadingZeroes(date.getSeconds())


    return formatted_date;

  }


  appendLeadingZeroes(n) {
    if (n <= 9) {
      return "0" + n;
    }
    return n
  }



}
