import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
   selector: 'embryo-LanguageDropDown',
   templateUrl: './LanguageDropDown.component.html',
   styleUrls: ['./LanguageDropDown.component.scss']
})
export class LanguageDropDownComponent implements OnInit {

   currentLang = '';

   langArray = [
      {
         name: "En",
         value: "en",
         flag: 'https://restcountries.eu/data/usa.svg'
      }, {
         name: "Fr",
         value: "fr",
         flag: 'https://restcountries.eu/data/fra.svg'
      }
   ]

   constructor(public translate: TranslateService) { }

   ngOnInit() {
      this.currentLang = localStorage.getItem('language') ? localStorage.getItem('language') : 'fr';
   }

   changeLanguage(currentLang) {

      localStorage.setItem('language', currentLang)
   }

}
