import { environment } from '../../../environments/environment';
import { EmbryoService } from '../../Services/Embryo.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { CommonSignInComponent } from '../../Global/CommonSignIn/CommonSignIn.component';

import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';

import { Meta, Title } from "@angular/platform-browser";

@Component({
  selector: 'embryo-Consentement',
  templateUrl: './Consentement.component.html',
  styleUrls: ['./Consentement.component.scss']
})
export class ConsentementComponent implements OnInit {

  imagesUrl = environment.imageTechnicUrl;

  user:any;

  type: string;
  currentLanguage = 'en';

  plasmapenform: FormGroup;
  bbglowform: FormGroup;

  generalInformations: FormGroup;

  trainings: any;

  QuestionsForm: FormGroup;

  validateForm: FormGroup;

  technicId = '';

  error:string = '';
  success:string = '';

  technics: any;
  
  constructor(
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private formGroup: FormBuilder,
    meta: Meta, title: Title,
    public dialog: MatDialog,
    private EmbService: EmbryoService
  ) {



    this.user = JSON.parse(localStorage.getItem('currentUser'));

    localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

    this.route.params.subscribe(params => {
      this.route.queryParams.forEach(queryParams => {
        this.type = queryParams['type'];
        if (this.type == undefined) {
          this.type = 'bbglow'
        }
      });
    });

    translate.onLangChange.subscribe(lang => {
      this.currentLanguage = lang;
    })

    this.technics = this.EmbService.technics;

    if (this.technics ==undefined ) {
      this.EmbService.getTechnics().then(
        resp=>{
          this.technics = resp['data']
        },
         error => {
           console.log(error)
         }
      )
    } 

  }

  ngOnInit() {


    window.scrollTo(0, 0);




    
  

    this.type == 'bbglow' ? this.technicId = "6f334167-ef85-425b-8e75-9e354129334b" : this.technicId = "87886390-a9c5-4ab8-befc-4d8901c7bd40"

    console.log(this.technicId);
    this.plasmapenform = this.formGroup.group({
      professionalEmail: ['', [Validators.required, Validators.email]],
      professionalName: ['', [Validators.required]],
      professionalPhoneNumber: [''],
      usedTechnic: [''],
      usedPigment: [''],
      technicId: ['', [Validators.required]],
    });

    this.bbglowform = this.formGroup.group({
      professionalEmail: ['', [Validators.required]],
      professionalName: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      treatment: ['', [Validators.required]],
      areaToUsed: ['', [Validators.required]],
      secrumUsed: ['', [Validators.required]]
    });


    this.generalInformations = this.formGroup.group({
      clientEmail: ['', [Validators.required, Validators.email]],
      clientName: ['', [Validators.required]],
      clientPhoneNumber: [''],
      clientCIN: [''],
      clientBirthdate: [''],
      clientAddress: ['']
    });

    this.QuestionsForm = this.formGroup.group({
      has_hemophilia: [false],
      is_diabetic: [false],
      has_hepatitis: [false],
      has_winter: [false],
      has_skin_diseases: [false],
      has_eczema: [false],
      has_allergies: [false],
      has_autoimmune: [false],
      has_prone_herpes: [false],
      has_infection: [false],
      has_epilepsy: [false],
      has_cardiovascular: [false],
      has_blood_thinners: [false],
      is_pregnant: [false],
      has_daily_medication: [false],
      has_pacemaker: [false],
      has_wound_healing: [false],
      has_drugs_alcohol: [false],
      has_surgery_radiation: [false],
    });


    this.validateForm = this.formGroup.group({
      gotCorrectInformation: ['', [Validators.required]],
      aggreed: ['', [Validators.required]],
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = event.lang
    });




  }


  getTrainingListes(){


    this.EmbService.getTraining().then(
      resp => {
        this.trainings = resp['data']
      },
      error=> {
        console.log(error)
      }

    )

  }


  validate() {

    let data: any = {};

    if (this.user && this.user !== null ) {

      if (this.generalInformations.valid) {
        for (let [key, value] of Object.entries(this.generalInformations.value)) {
          data[`${key}`] = `${value}`;
        }
  
      } else {
        for (let i in this.generalInformations.controls) {
          this.generalInformations.controls[i].markAsTouched();
  
          window.scrollTo(0, 1000)
        }
      }
  
  
      if (this.plasmapenform.valid) {
        for (let [key, value] of Object.entries(this.plasmapenform.value)) {
          data[`${key}`] = `${value}`;
        }
  
      } else {
        for (let i in this.plasmapenform.controls) {
          this.plasmapenform.controls[i].markAsTouched();
          window.scrollTo(0, 500)
        }
      }
  
      let questionsContent = [
  
        { "content": "Hemophilia?", "switchActive": this.QuestionsForm.value.has_hemophilia },
        { "content": "Diabetes?", "switchActive": this.QuestionsForm.value.is_diabetic },
        { "content": "Hepatitis A, B, C, D, E, F?", "switchActive": this.QuestionsForm.value.has_hepatitis },
        { "content": "Winter +?", "switchActive": this.QuestionsForm.value.has_winter },
        { "content": "Skin diseases?", "switchActive": this.QuestionsForm.value.has_skin_diseases },
        { "content": "Eczema?", "switchActive": this.QuestionsForm.value.has_eczema },
        { "content": "Allergies?", "switchActive": this.QuestionsForm.value.has_allergies },
        { "content": "Autoimmune disease?", "switchActive": this.QuestionsForm.value.has_autoimmune },
        { "content": "Are you prone to herpes?", "switchActive": this.QuestionsForm.value.has_prone_herpes },
        { "content": "Infectious diseases / high fever?", "switchActive": this.QuestionsForm.value.has_infection },
        { "content": "Epilepsy?", "switchActive": this.QuestionsForm.value.has_epilepsy },
        { "content": "Cardiovascular problems?", "switchActive": this.QuestionsForm.value.has_cardiovascular },
        { "content": "blood medication (blood thinners)?", "switchActive": this.QuestionsForm.value.has_blood_thinners },
        { "content": "Are you pregnant?", "switchActive": this.QuestionsForm.value.is_pregnant },
        { "content": "daily medication?", "switchActive": this.QuestionsForm.value.has_daily_medication },
        { "content": "a pacemaker?", "switchActive": this.QuestionsForm.value.has_pacemaker },
        { "content": "problems with wound healing?", "switchActive": this.QuestionsForm.value.has_wound_healing },
        { "content": "Drugs / Alcohol in the past 24 hours?", "switchActive": this.QuestionsForm.value.has_drugs_alcohol },
        { "content": "14-day surgery / radiation or medical intervention?", "switchActive": this.QuestionsForm.value.has_surgery_radiation },
  
      ]
      for (let [key, value] of Object.entries(this.QuestionsForm.value)) {
        data[`${key}`] = `${value}`;
      }
  
      data["questions"] = questionsContent
      data["userId"] = this.user.userId;
  
      if (this.generalInformations.valid && this.plasmapenform.valid && this.QuestionsForm.valid  ) {

        this.EmbService.SendNewConsent(data).then(

          resp => {
            if (resp['status'] =='204' ) {
                this.success = "votre formulaire a été envoyé avec succes";
                window.location.reload();
  
            } else {
              this.error = "une erreur c'est produit, veuillez réessayer"
            }
            window.scrollTo(0, 500)
          },
          error => {
            this.error = "une erreur c'est produit, veuillez réessayer"
          }
        )
    

      }


      setTimeout(() => {
        this.success = '';
        this.error = '';
     }, 5000);

    } else {

     this.dialog.open(CommonSignInComponent, {
        width: '450px',
      });
    }
  


    // console.log(questionsContent);

    // console.log(this.QuestionsForm.value)

  }


}
