import { environment } from '../../../environments/environment';
import { Component, OnInit, Input, OnChanges, Renderer2, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import { Meta, Title } from "@angular/platform-browser";

declare var $: any;

import { EmbryoService } from '../../Services/Embryo.service';

@Component({
   selector: 'embryo-ShopDetails',
   templateUrl: './ShopDetails.component.html',
   styleUrls: ['./ShopDetails.component.scss']
})
export class ShopDetailsComponent implements OnInit, OnChanges {

   @Input() detailData: any;
   @Input() currency: string;

   mainImgPath: string;
   totalPrice: any;
   type: any;
   colorsArray: string[] = ["Red", "Blue", "Yellow", "Green"];
   sizeArray: number[] = [36, 38, 40, 42, 44, 46, 48];
   quantityArray: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
   productReviews: any;

   sectionParents: any = [];
   sectionChilds: any = [];

   baseUrl:string = 'https://www.youtube.com/embed/';
   videoUrl: any ;


   sections: any;
   currentLanguage: any;
   imagesUrl: any;
   id: any;
   productGallery: any
   constructor(
      private route: ActivatedRoute,
      private router: Router,
      public meta: Meta,
      public title: Title,
      private sanitizer: DomSanitizer,
      public translate: TranslateService,
      public embryoService: EmbryoService
   ) {
      //this.embryoService.getProductReviews().valueChanges().subscribe(res => {this.productReviews = res});

      localStorage.getItem('language') ? this.currentLanguage = localStorage.getItem('language') : this.currentLanguage = "fr"

      translate.onLangChange.subscribe(lang => {
         this.currentLanguage = lang;
      })

      title.setTitle(this.detailData ? this.detailData.name : 'Fabulous');

      meta.addTags([
         { name: 'description', content: this.detailData ? this.detailData.meta_description : '' }
      ]);
   }

   ngOnInit() {

      this.type == 'session' ? this.imagesUrl = environment.imageSessioncUrl : this.imagesUrl = environment.imageArticlecUrl;


      if (this.type == 'session') {

         this.mainImgPath = this.detailData.sessionphoto1?  this.imagesUrl + this.detailData.sessionphoto1 :  'assets/images/no-image.jpg'; 

      } else if (this.type == 'training') {

         this.mainImgPath = this.detailData.photo1 ? this.imagesUrl + this.detailData.photo1 :  'assets/images/no-image.jpg'; 

      } else {

         this.mainImgPath = this.detailData.photo1 ? this.imagesUrl + this.detailData.photo1 :  'assets/images/no-image.jpg'; 
      }



      //this.mainImgPath = this.detailData.photo1 ? this.imagesUrl + this.detailData.photo1 : 'assets/images/no-image.jpg';

      this.route.params.subscribe(res => {
         this.id = res.id;
         this.type = res.type;

         if (this.type == 'session') {

            this.totalPrice = this.detailData.priceTTC

         } else if (this.type == 'training') {

            this.totalPrice = this.detailData.price_ttc

            this.getTrainingSections()

         } else {

            this.totalPrice =  this.detailData.price_ttc //this.detailData.price_TTC
         }
      })

      this.title.setTitle(this.detailData.name);

      this.productGallery = [
         { img: this.detailData.photo2 },
         { img: this.detailData.photo3 },
         { img: this.detailData.photo4 },
         { img: this.detailData.photo5 },
         { img: this.detailData.photo6 },
      ]

      this.route.params.subscribe(res => {
         this.type = res.type;
      });

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
         this.currentLanguage = event.lang
      });

      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.detailData.descriptionVideoId);    

   }



   ngOnChanges() {

      this.type == 'session' ? this.imagesUrl = environment.imageSessioncUrl : this.imagesUrl = environment.imageArticlecUrl;

      this.mainImgPath = null;
      this.totalPrice = null;
      this.mainImgPath = this.detailData.photo1 ? this.imagesUrl + this.detailData.photo1 : 'assets/images/no-image.jpg';


      this.route.params.subscribe(res => {
         this.id = res.id;
         this.type = res.type;

         if (this.type == 'session') {

            this.totalPrice = this.detailData.priceTTC

         } else if (this.type == 'training') {

            this.totalPrice = this.detailData.price_ttc

         } else {

            this.totalPrice = this.detailData.price_ttc  //this.detailData.price_TTC
         }

      })
      //this.totalPrice  = this.detailData.price_TTC ? this.detailData.price_TTC : this.detailData.priceTTC; 
      this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.baseUrl + this.detailData.descriptionVideoId);    

      
   }

   /**
    * getImagePath is used to change the image path on click event. 
    */
   public getImagePath(imgPath: string, index: number) {
      $('.p-link').removeClass('border-active');
      this.mainImgPath = imgPath;
      $("#" + index + "_img").addClass('border-active');
   }

   public calculatePrice(detailData: any, value: any) {
      detailData.quantity = value;

      if (this.type == 'session') {

         this.totalPrice = this.detailData.priceTTC * value

      } else if (this.type == 'training') {

         this.totalPrice = this.detailData.price_ttc * value

      } else {

         this.totalPrice = this.detailData.price_ttc * value
      }

   }

   public reviewPopup(detailData) {
      let reviews: any = null;
      for (let review of this.productReviews) {
         // if((review.id == detailData.id) && (review.type == detailData.type) && (review.category == detailData.category)){
         //    singleProduct = review;
         //    break;
         // }

         reviews = review.user_rating;
      }

      this.embryoService.reviewPopup(detailData, reviews);
   }

   public addToWishlist(value: any) {
      this.embryoService.addToWishlist(value);
   }

   public addToCart(value: any , kit?) {
      this.embryoService.addToCart(value,"",kit);
   }

   public buyNow(value: any) {
      this.embryoService.buyNow(value);
      this.router.navigate(['/checkout']);
   }


   getTrainingSections() {

      this.embryoService.getTrainingSections(this.id).then(
         resp => {
            this.sections = resp['data']
            this.sections.forEach(element => {
               if (element.parentId == null ) {
                  this.sectionParents.push(element)
               } else {
                  this.sectionChilds.push(element)
               }
            });
         },
         error => {
            console.log(error)
         }
      )

   }


   CalculePrice(Price,tax){
      
      let PriceTTC = 0;
      let _tax = Number(tax) || 0 ;
      let _price = Number(Price) || 0 ;
      _tax.toFixed(2);
      _price.toFixed(2);

      try {
         PriceTTC =  (( _price * _tax / 100 ) + _price );
      } catch (error) {
         PriceTTC = 0
      }
      return PriceTTC;
   }



   public showDate(StartDate, EndDate) {

      let date = ''
      const startDay = new Date(StartDate).getDate();
      const endDay = new Date(EndDate).getDate();

      const startMonth = new Date(StartDate).getMonth() + 1;
      const endMonth = new Date(EndDate).getMonth() + 1;

      const startYear = new Date(StartDate).getFullYear();
      const endYear = new Date(EndDate).getFullYear();

      if (startYear === endYear) {

         if (startMonth === endMonth) {

            date = startDay + ' - ' + endDay + '/' + startMonth + '/' + startYear;
         } else {

            date = startDay + '/' + startMonth + ' - ' + endDay + '/' + endMonth + '-' + startYear;
         }

      } else {

         date = startDay + '/' + startMonth + '/' + startYear + ' - ' + endDay + '/' + endMonth + '/' + endYear;
      }

      return date;

   }



}
